<template>
  <div class="aboutRM home">
    <!-- <div class="aboutTitle">IMG Section</div> -->
    <!-- <h2>회사소개</h2> -->
    <section class="company-section1">
      <div>
        <img src="@/assets/images/reviewmind/circle.png" alt="이미지" />
        <h2>{{ $t("company_title_1") }}</h2>
        <p>
          {{ $t("company_context_1_1") }}
          <br />{{ $t("company_context_1_2") }} <br />{{
            $t("company_context_1_3")
          }}
          <br />{{ $t("company_context_1_4") }}
        </p>
      </div>
      <img src="@/assets/images/reviewmind/product_img.png" alt="이미지" />
    </section>
    <section class="company-section2">
      <img src="@/assets/images/reviewmind/circle.png" alt="이미지" />
      <h2>{{ $t("company_title_2") }}</h2>
      <div>
        <div>
          <div>
            <h3>{{ $t("company_influence_title") }}</h3>
            <span> <i class="fas fa-caret-up"></i> 10% </span>
          </div>
          <p>{{ $t("company_influence") }}</p>
        </div>
        <div>
          <div>
            <h3>{{ $t("company_sales_title") }}</h3>
            <span> <i class="fas fa-caret-up"></i> 18% </span>
          </div>
          <p>{{ $t("company_sales") }}</p>
        </div>
        <div>
          <div>
            <h3>{{ $t("company_conversion_title") }}</h3>
            <span> <i class="fas fa-caret-up"></i> 4.6% </span>
          </div>
          <p>{{ $t("company_conversion") }}</p>
        </div>
      </div>
    </section>
    <section class="company-section3">
      <h2>
        {{ $t("company_title_3") }}
        <br />{{ $t("company_title_3_1") }}
      </h2>
      <img src="@/assets/images/reviewmind/about-section3.png" alt="이미지" />
    </section>
    <section class="company-section4">
      <div>
        <img src="@/assets/images/reviewmind/about_logo1.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo2.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo3.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo4.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo5.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo6.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo7.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo8.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo9.png" alt="로고" />
        <img src="@/assets/images/reviewmind/about_logo10.png" alt="로고" />
      </div>
    </section>
    <div class="mainSection4">
      <div class="section4Wrap">
        <h2>{{ $t("demo_request") }}</h2>
        <button @click="move">Watch Demo</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("GNB_MENU", 2);
  },
  methods: {
    move() {
      this.$router.push("/watchdemo");
    },
  },
};
</script>
